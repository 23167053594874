import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { errorInterceptor } from '../core/errorInterceptor';
import Header from '../components/Header';
import { Col, Container, Row } from 'react-bootstrap';
// import CustomButton from '../components/CustomButton';
import Footer from '../components/Footer';

function ViewArticle(props) {

  let { id } = useParams();
  const navigate = useNavigate();

  const [article, setArticle] = useState({});

  const [articlePicUrl, setArticlePicUrl] = useState()
  const [defaultPicUrl] = useState(`${require('../assets/placeholder-image.png')}`);

  const goBack = () => {
    navigate(-1);
  }

  const getArticle = async () => {
    axios.get(`${process.env.REACT_APP_API_URL}/articles/${id}`)
      .then(res => {
        console.log(res.data);
        setArticle(res.data);
        let url = `${process.env.REACT_APP_API_URL}/articles/download/file/path/${res.data?.article_attachments[0]?.attachment.id}`;
        setArticlePicUrl(url);
      })
      .catch(err => {
        errorInterceptor(err)
      })
  }

  useEffect(() => {
    getArticle();
    // eslint-disable-next-line
  }, []);

  const timeAgo = (date) => {
    // example date format: 2023-10-31T14:35:18
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }

    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }

    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }

    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }

    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }

    return Math.floor(seconds) + " seconds ago";
  }

  return (
    <>
      <Header link={props.link} />
      <main>
        <Container className='mb-4'>
          <Row style={{ marginTop: 50 }}>
            <Col>
              <h5 className='link-text' onClick={goBack}>
              &#8592; Go Back
              </h5>
              <h3 className='article-header-title text-main'>{article.title}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{timeAgo(article.created_at)}</p>
            </Col>
          </Row>
          <Row>
            <div className='mb-4'>
              <img className="article-header-image" src={article.article_attachments?.length > 0 ? articlePicUrl : defaultPicUrl} alt={article.title} />
            </div>
          </Row>
          <Row>
            <Col>
              <div className='article-header-content' dangerouslySetInnerHTML={
                { __html: article.description }
              }></div>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default ViewArticle