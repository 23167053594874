import React from 'react';
import { Controller } from 'react-hook-form';

export default function CustomInput({ control, name, rules = {}, placeholder, secureTextEntry, width, isPassword, color, type = 'text', autoComplete = 'off', required = false, id, disabled = false, min, max, maxLength, textArea = false, rows = 3, errors }) {

    return (
        <>
            {!textArea && <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <>
                        <input
                            type={type}
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            className='input-control'
                            autoComplete={autoComplete}
                            required={required}
                            onChange={onChange}
                            id={id}
                            disabled={disabled}
                            min={min}
                            max={max}
                            maxLength={maxLength}
                        />
                        {errors?.type === 'required' && <p role="alert" className='error-text'>{placeholder} is required</p>}
                    </>
                )}
            />}
            {textArea && <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <div className="">
                        <textarea
                            rows={rows}
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            className='textarea-control'
                            autoComplete={autoComplete}
                            required={required}
                            onChange={onChange}
                            id={id}
                            disabled={disabled}
                        />
                    </div>
                )}
            />}
        </>
    );
}
