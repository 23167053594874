import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Header from '../components/Header';
import { Container, Row } from 'react-bootstrap';
import CustomButton from '../components/CustomButton';
import { ColorRing } from 'react-loader-spinner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input';
import toastr from '../core/toast';
import CountDownTimer from '../components/CountDownTimer';
import api from '../core/api';
import { errorInterceptor } from '../core/errorInterceptor';

function ConfirmOtp(props) {

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [otp, setOtp] = useState('');
    const [isResending, setIsResending] = React.useState(false)
    const [isResendEnable, setIsResendEnable] = React.useState(true)
    const { email } = location.state || {};

    function obscureEmail(email) {
        const [name, domain] = email.split('@');
        return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
    };

    // react hook form for form validation
    const { handleSubmit, setValue } = useForm(
    );

    const onSubmit = (data) => {
        setIsLoading(true)

        let user = {...data, email: email}

        return new Promise((resolve, reject) => {
            api.post('/auth/confirm-otp', user)
            .then(response => {
                resolve(response)
                navigate('/reset-password', {
                    state : {
                        email: email,
                        otp: data.otp,
                    }
                })
            }).catch((error) => {
                reject(error)
                errorInterceptor(error)
                setIsLoading(false);
            });
        })
    }

    const onResendPressed = () => {
        setIsLoading(true)
        setIsResending(true)

        return new Promise(async (resolve, reject) => {
            api.post('/auth/mobile-forgot-password', { email: email })
                .then(response => {
                    toastr.success('An OTP has been resent to your email')
                    resolve(response);
                    setIsResendEnable(true)
                    setIsResending(false)
                    setIsLoading(false)
                })
                .catch(error => {
                    reject(error);
                    errorInterceptor(error);
                    setIsLoading(false);
                    setIsResendEnable(true)
                    setIsResending(false)
                });
        });

    }

    const deactivateResetIsEnabled = () => {
        setIsResendEnable(false)
    }

    useEffect(() => {
        if (otp.length === 6) {
            setValue('otp', parseInt(otp))
        }
    }, [otp, setValue])

    return (
        <>
            <Header link={props.link} />
            <main>
                <Container style={{
                    marginBottom: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 600,
                }}>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row style={{ marginTop: 50 }}>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Confirm OTP</h1>
                            <p className='description'>
                                Please enter the OTP sent to your email {obscureEmail(email)}
                            </p>
                        </Row>
                        <Row style={{ marginTop: 20 }} className='justify-content-center align-items-center'>
                            <div style={{ marginBottom: 10 }}>
                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                    containerStyle={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    inputStyle={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '4px',
                                        border: '1px solid #e5e5e5',
                                        fontSize: '18px',
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                        marginRight: '10px',
                                        marginLeft: '10px',
                                        color: '#000',
                                        textAlign: 'center'
                                    }}
                                />
                            </div>
                        </Row>
                        <div style={{ marginTop: 10 }} className='text-center text-white py-3'>
                            <div>
                                {!isLoading && !isResending &&
                                    <CustomButton fullWidth={true} className='primary-button' href='#!' role='button' disabled={otp.length !== 6}>
                                        Confirm
                                    </CustomButton>}
                                {(isLoading || isResending) &&
                                    <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                                        <ColorRing
                                            visible={true}
                                            height="50"
                                            width="50"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                        />
                                    </CustomButton>
                                }
                                {!isResending &&
                                    <CustomButton className='link-button' onClick={onResendPressed} disabled={isResendEnable}>
                                        {isResendEnable ? <CountDownTimer isResendEnable={isResendEnable} setResendEnabled={deactivateResetIsEnabled} /> : "Resend Code"}
                                    </CustomButton>
                                }
                            </div>
                            <Row style={{ marginTop: 20 }} className='text-center'>
                                <font className='link-text'>I remember my password. <Link to={'/sign-in'} className='link text-danger'>Login</Link></font>
                            </Row>
                        </div>
                    </form>
                </Container>
            </main>
        </>
    )
}

export default ConfirmOtp