import React, { useEffect } from 'react'
import Header from '../components/Header'
import { Container, Row } from 'react-bootstrap'
import CustomInput from '../components/CustomInput'
import { useForm } from 'react-hook-form'
import Label from '../components/Label'
import CustomButton from '../components/CustomButton'
import { useAuth } from '../context/AuthContext'
import { ColorRing } from 'react-loader-spinner'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { errorInterceptor } from '../core/errorInterceptor'


export default function Login(props) {

    let auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const { email } = location.state || {};

    // react hook form for form validation
    const { control, handleSubmit, formState: { errors } } = useForm(
        {
            defaultValues: {
                username: email || "",
                password: ""
            }
        }
    );

    useEffect(() => {
        const user = auth.user;
        if (user && user.password_reset) {
            navigate('/confirm-account', {
                state: {
                    email: user.email,
                    phone_number: user.phone_number
                }
            });
        }
        if (user && !user.password_reset) {
            navigate('/');
        }
        // eslint-disable-next-line
    }, []);

    // handle form submit
    const onSubmit = (data) => {
        setIsLoading(true);
        auth.login(data)
            .then(response => {
                setIsLoading(false);
                let user = response.data;
                if (user && user.password_reset) {
                    navigate('/confirm-account', {
                        state: {
                            email: user.email,
                            phone_number: user.phone_number
                        }
                    });
                }
                if (user && !user.password_reset) {
                    navigate('/');
                }
            })
            .catch(error => {
                setIsLoading(false);
                if (error?.response?.status === 401) {
                    errorInterceptor(error?.response?.data?.message);
                }
            })
    };

    return (

        <>
            <Header link={props.link} />
            <main>
                <Container style={{
                    marginBottom: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 600,
                }}>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row style={{ marginTop: 50 }}>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Sign into your account</h1>
                            <p className='description'>Please enter your login details to access your account.</p>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <div style={{ marginBottom: 10 }}>
                                <Label forInput="username" value="Email Address" />
                                <CustomInput
                                    control={control}
                                    name="username"
                                    placeholder={'Email Address'}
                                    rules={{ required: "Email is required" }}
                                    errors={errors.username}
                                />
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <Label forInput="password" value="Password" />
                                <CustomInput
                                    control={control}
                                    name="password"
                                    placeholder={'Password'}
                                    type='password'
                                    rules={{ required: "Password is required" }}
                                    errors={errors.password}
                                />
                            </div>
                        </Row>
                        <Row className='text-end'>
                            <Link to={`/forgot-password`} className='link text-danger'>Forgot Password</Link>
                        </Row>
                        <div style={{ marginTop: 10 }} className='text-left text-white py-3'>
                            <div>
                                {!isLoading && <CustomButton fullWidth={true} className='primary-button' href='#!' role='button'>
                                    Login
                                </CustomButton>}
                                {isLoading &&
                                    <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                                        <ColorRing
                                            visible={true}
                                            height="50"
                                            width="50"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                        />
                                    </CustomButton>
                                }
                            </div>
                            <Row style={{ marginTop: 20 }} className='text-center'>
                                <font className='link-text'>I do not have an account. <Link to={'/sign-up'} className='link text-danger'>Create an account</Link></font>
                            </Row>
                        </div>
                    </form>
                </Container>
            </main>
        </>

    )
}
