import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import CustomInput from '../components/CustomInput';
import { useForm } from 'react-hook-form';
import CustomButton from '../components/CustomButton';
import toastr from '../core/toast';
import api from '../core/api';
import { errorInterceptor } from '../core/errorInterceptor';
import { ColorRing } from 'react-loader-spinner';
import { useAuth } from '../context/AuthContext';

function View(props) {

    let { id } = useParams();
    const auth = useAuth();
    const navigate = useNavigate();
    const [kidsPicUrl, setKidsPicUrl] = useState()
    const [defaultPicUrl] = useState(`${require('../assets/placeholder-image.png')}`);

    const [kid, setKid] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [mode, setMode] = useState('view');

    // react hook form for form validation
    const { control, handleSubmit, reset } = useForm(
        {
            defaultValues: {
                comment: '',
            }
        }
    );

    const goBack = () => {
        navigate(-1);
    }

    const getKid = (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/kids/${id}`)
            .then(res => {
                setKid(res.data)
                let url = `${process.env.REACT_APP_API_URL}/kids/download/file/path/${res.data?.kid_attachments[0]?.attachment.id}`;
                setKidsPicUrl(url);
            })
            .catch(err => {
                errorInterceptor(err)
            })
    }


    useEffect(() => {
        getKid(id)
    }, [id])

    // convert date string to full date format Friday, August 13, 2021
    const convertDate = (date) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    }

    // get age from date of birth
    const getAge = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        // if age is less than 1 but greater than 0 months return months
        if (age < 1 && (today.getMonth() - birthDate.getMonth()) > 0) {
            return `${today.getMonth() - birthDate.getMonth()} months`
        }

        // if age is less than 1 month return days
        if (age < 1 && (today.getMonth() - birthDate.getMonth()) < 1) {
            return `${today.getDate() - birthDate.getDate()} days`
        }

        // if age is equal to 1 return year else return years
        if (age === 1) {
            return age + " year";
        }

        return age + " years";
    }

    // get year from date
    const getYear = (date) => {
        const options = { year: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    }

    const timeAgo = (date) => {
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);

        let interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }

        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }

        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }

        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }

        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }

        return Math.floor(seconds) + " seconds ago";
    }

    const onSubmit = (data) => {
        setIsLoading(true)

        data.kid_id = kid.id;

        return new Promise((resolve, reject) => {
            api.post(`/kids/post/comment`, data)
                .then(response => {
                    getKid(id)
                    toastr.success('Comment posted');
                    setIsLoading(false)
                    reset()
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                    if (error.response.status !== 401) {
                        errorInterceptor(error)
                    }
                    if (error.response.status === 401) {
                        auth.logout()
                    }
                    setIsLoading(false)
                })
        })

    }

    const onSubmitReport = (data) => {
        setIsLoading(true)
        data.kid_id = kid.id;

        return new Promise((resolve, reject) => {
            api.post(`/kids/post/sitting`, data)
                .then(response => {
                    getKid(id)
                    toastr.success('Sighting reported');
                    setIsLoading(false)
                    reset()
                    setMode('view')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                    if (error.response.status !== 401) {
                        errorInterceptor(error)
                    }
                    if (error.response.status === 401) {
                        auth.logout()
                    }
                    setIsLoading(false)
                })
        })

    }

    return (
        <>
            <Header link={props.link} />
            <main>
                <Container>
                    <Row style={{ marginTop: 50 }}>
                        <Col>
                            <h1 className='header-title text-main'>{kid.first_name} {kid.last_name}</h1>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col sm={12} md={5} lg={4} className='my-col'>
                            <Card className='single-kids-section-column' style={{ height: '320px', border: 'none' }}>
                                {kid.kid_attachments?.length > 0 && <img variant="top" alt={`child-${kid.id}`} className='single-kid-image' src={kidsPicUrl} />}
                                {kid.kid_attachments?.length === 0 && <img variant="top" alt={`child-${kid.id}`} className='single-kid-image' src={defaultPicUrl} />}
                                {kid.status === "MISSING" && <div className="missing-status-tag"></div>}
                                {kid.status === "FOUND" && <div className="found-status-tag"></div>}
                                {kid.status === "UNKNOWN" && <div className="unknown-status-tag"></div>}
                            </Card>
                            <Row style={{ width: '17rem', marginTop: 30 }}>
                                <CustomButton fullWidth={true} className='primary-button-unknown' onClick={goBack} role='button'>
                                    Go Back
                                </CustomButton>
                            </Row>

                            <Row style={{ width: '17rem', marginTop: 20 }}>
                                <CustomButton fullWidth={true} className='primary-button-unknown-outline' onClick={() => setMode('report')} role='button'>
                                    Report Sighting
                                </CustomButton>
                            </Row>

                        </Col>
                        {mode === 'view' && <Col sm={12} md={7} lg={8} className='my-col' style={{ marginTop: 10 }}>
                            <h1 className='sub-title text-main'>Details</h1>
                            <p className='text-dl text-gray'>Status: <span className="text-dt">{kid?.status}</span></p>
                            <p className='text-dl text-gray'>Missing Since: <span className="text-dt">{convertDate(kid?.date_last_seen)}</span></p>
                            <p className='text-dl text-gray'>Location Last Seen: <span className="text-dt">{kid?.location_last_seen}</span></p>
                            <p className='text-dl text-gray'>Year of birth: <span className="text-dt">{getYear(kid?.date_of_birth)}</span></p>
                            <p className='text-dl text-gray'>Age now: <span className="text-dt">{getAge(kid?.date_of_birth)}</span></p>
                            <p className='text-dl text-gray'>Gender: <span className="text-dt">{kid?.gender}</span></p>
                            {kid?.height && <p className='text-dl text-gray'>Height: <span className="text-dt">{kid?.height}cm</span></p>}
                            {kid?.weight && <p className='text-dl text-gray'>Weight: <span className="text-dt">{kid?.weight}kg</span></p>}
                            {kid?.distinguishing_features && <p className='text-dl text-gray'>Distinguishing Features: <span className="text-dt">{kid?.distinguishing_features}</span></p>}

                            <Row style={{ marginTop: 20 }}>
                                <h1 className='sub-title text-main'>Police Station</h1>
                                <p className='text-dl text-gray'>Name: <span className="text-dt">{kid?.station?.name}</span></p>
                                <p className='text-dl text-gray'>Address: <span className="text-dt">{kid?.station?.address}</span></p>
                                <p className='text-dl text-gray'>Country: <span className="text-dt">{kid?.station?.country?.name}</span></p>
                            </Row>

                            <Row style={{ marginTop: 20 }}>
                                <h1 className='sub-title text-main'>Circumstances</h1>
                                <p className='description'>{kid?.circumstances}</p>
                            </Row>

                            <Row style={{ marginTop: 20 }}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <h3 className='comment-section-title text-main'>Post a comment</h3>
                                    <CustomInput control={control} textArea={true} name="comment" rows="3" placeholder="Comment" autoComplete="comment" />
                                    <div className='d-flex justify-content-between'>
                                        <Col></Col>
                                        <Col></Col>
                                        <Col>
                                            {!isLoading && <CustomButton fullWidth={true} className='secondary-primary-button' type='submit'>
                                                Post Comment
                                            </CustomButton>}
                                            {isLoading &&
                                                <CustomButton fullWidth={true} disabled={true} className='secondary-primary-button' href='#!' role='button'>
                                                    <ColorRing
                                                        visible={true}
                                                        height="45"
                                                        width="45"
                                                        ariaLabel="blocks-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass="blocks-wrapper"
                                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                                    />
                                                </CustomButton>}
                                        </Col>
                                    </div>
                                </form>
                            </Row>

                            <h1 className='sub-title text-main'>Comments</h1>
                            {kid.comments && kid.comments.length === 0 && <>
                                <div className="d-flex justify-content-center align-items-center" style={{ height: "10vh", marginBottom: 30 }}>
                                    <Card border="light" style={{ border: 'none' }}>
                                        <Card.Body className="text-center">
                                            <i className="bi bi-emoji-frown" style={{ fontSize: "10rem" }} />

                                            <Card.Text className='description'>
                                                No comments yet
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </>}
                            {kid.comments && kid.comments.length > 0 && <Row style={{ marginTop: 20, marginBottom: 30, maxHeight: '300px', overflow: 'auto' }}>
                                {kid.comments && kid.comments.map((comment, index) => (
                                    <div className='comment' key={index}>
                                        <Col>
                                            <div className='d-flex justify-content-between'>
                                                <div className='comment-user'>{comment.user.first_name} {comment.user.last_name}</div>
                                                <div className='comment-date'>{timeAgo(comment.created_at)}</div>
                                            </div>
                                            <p className='comment-body'>{comment.comment}</p>
                                        </Col>
                                    </div>
                                ))}
                            </Row>}

                        </Col>}
                        {mode === 'report' && <Col sm={12} md={7} lg={8} className='my-col' style={{ marginTop: 10 }}>

                            <h1 className='sub-title text-main'>Report Sighting</h1>
                            <p className='description'>Please provide any additional information you think is relevant to the sighting.</p>
                            <form onSubmit={handleSubmit(onSubmitReport)}>
                                <CustomInput control={control} textArea={true} name="comment" rows="5" placeholder="Sighting Details" />
                                <div className='d-flex justify-content-between' style={{ marginTop: 20 }}>
                                    <Col>
                                        {!isLoading && <CustomButton fullWidth={true} className='primary-button' type='submit'>
                                            Report Sighting
                                        </CustomButton>}
                                        {isLoading &&
                                            <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                                                <ColorRing
                                                    visible={true}
                                                    height="45"
                                                    width="45"
                                                    ariaLabel="blocks-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="blocks-wrapper"
                                                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                                />
                                            </CustomButton>}
                                    </Col>
                                    <Col style={{ marginLeft: 10 }}>
                                        <CustomButton fullWidth={true} className='primary-button-outline' onClick={() => setMode('view')} role='button'>
                                            Cancel
                                        </CustomButton>
                                    </Col>
                                    <Col></Col>
                                    <Col></Col>
                                </div>
                            </form>
                        </Col>
                        }
                    </Row>
                </Container>

            </main>
            <Footer />
        </>
    )
}

export default View