import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { faTwitter, faFacebook, faInstagram, faYoutube, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Navigation from './views/Navigation';
import { AuthProvider } from './context/AuthContext';
import { ErrorBoundary, LEVEL_ERROR, Provider } from '@rollbar/react';
import FrontEndError from './views/errors/FrontEndError';

library.add(faTwitter, faFontAwesome, faFacebook, faInstagram, faYoutube, faMars, faVenus)

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENV,
};

const ErrorDisplay = ({ error, resetError }) => <FrontEndError />

function App() {

  return (
    <>
      <Provider config={rollbarConfig}>
        <ErrorBoundary level={LEVEL_ERROR} fallbackUI={ErrorDisplay}>
          <AuthProvider>
            <Navigation />
          </AuthProvider>
        </ErrorBoundary>
      </Provider>

    </>
  );
}

export default App;
