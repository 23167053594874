import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Header from '../components/Header';
import { Container, Row } from 'react-bootstrap';
import Label from '../components/Label';
import CustomInput from '../components/CustomInput';
import CustomButton from '../components/CustomButton';
import { ColorRing } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import { errorInterceptor } from '../core/errorInterceptor';
import api from '../core/api';

function ForgotPassword(props) {

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // react hook form for form validation
    const { control, handleSubmit, formState: { errors } } = useForm(
        {
            defaultValues: {
                email: "",
            }
        }
    );


    const onSubmit = (data) => {
        setIsLoading(true);
        return new Promise(async (resolve, reject) => {
            api.post('/auth/mobile-forgot-password', data)
                .then(response => {
                    resolve(response);
                    // navigate to a different page
                    navigate('/confirm-otp', { state: { email: data.email } })
                })
                .catch(error => {
                    reject(error);
                    errorInterceptor(error);
                    setIsLoading(false);
                });
        });
    }


    return (

        <>
            <Header link={props.link} />
            <main>
                <Container style={{
                    marginBottom: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 600,
                }}>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row style={{ marginTop: 50 }}>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Forgot Password</h1>
                            <p className='description'>
                                Please enter your email address to confirm your
                                account and we will send you an OTP to reset your
                                password.
                            </p>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <div style={{ marginBottom: 10 }}>
                                <Label forInput="username" value="Email Address" />
                                <CustomInput
                                    control={control}
                                    name="email"
                                    placeholder={'Email Address'}
                                    rules={{ required: "Email is required" }}
                                    errors={errors.email}
                                />
                            </div>
                        </Row>
                        <div style={{ marginTop: 10 }} className='text-left text-white py-3'>
                            <div>
                                {!isLoading && <CustomButton fullWidth={true} className='primary-button' href='#!' role='button'>
                                    Submit
                                </CustomButton>}
                                {isLoading &&
                                    <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                                        <ColorRing
                                            visible={true}
                                            height="50"
                                            width="50"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                        />
                                    </CustomButton>
                                }
                            </div>
                            <Row style={{ marginTop: 20 }} className='text-center'>
                                <font className='link-text'>I remember my password. <Link to={'/sign-in'} className='link text-danger'>Login</Link></font>
                            </Row>
                        </div>
                    </form>
                </Container>
            </main>
        </>

    )
}

export default ForgotPassword