import { Card } from "react-bootstrap";
import { useEffect } from "react";
import { useAuth } from "../../context/AuthContext";

function FrontEndError() {
    let auth = useAuth();

    useEffect(() => {
        auth?.errorPage || (auth?.updateErrorPage && auth.updateErrorPage(true));

        return () => {
            auth?.updateErrorPage && auth.updateErrorPage(false);
        }
    }, [auth]);
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <Card border="light">
                <Card.Body className="text-center">
                    <i className="bi bi-emoji-frown" style={{ fontSize: "10rem" }} />
                    <Card.Title className="text-uppercase">
                        Page Error
                    </Card.Title>
                    <Card.Text>
                        An error occurred while loading the page.
                    </Card.Text>
                    <div className="d-grid gap-2">
                        <a style={{ width: '100%' }} className='primary-button-link' href='/' role='button'>
                            Go to Home
                        </a>
                    </div>
                </Card.Body>
            </Card>
        </div>

    )
}
export default FrontEndError;