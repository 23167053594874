import React, { createContext, useCallback } from 'react';
import axios from 'axios';
import qs from 'qs';
import { errorInterceptor } from '../core/errorInterceptor';
import 'react-toastify/dist/ReactToastify.css';
import { useRollbarPerson } from '@rollbar/react';
import api, {
    setToken as setApiToken, setHandler, postRequest,
    getRequest, putRequest, deleteRequest
} from '../core/api';



export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const auth = useAuthProvider();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;

}

export const useAuth = () => {
    return React.useContext(AuthContext);
}

// Provider hook that creates auth object and handles state
function useAuthProvider() {

    let baseURL = process.env.REACT_APP_API;

    const [isLoading, setIsLoading] = React.useState(false);
    const [user, setUser] = React.useState(null);
    const [token, setToken] = React.useState(null);
    const [userToken, setUserToken] = React.useState(null);
    const [errorPage, setErrorPage] = React.useState(false);


    const updateUser = (user) => {
        setUser(user);
    }

    const updateErrorPage = (errorPage) => {
        setErrorPage(errorPage);
    }


    const login = async (data) => {

        setIsLoading(true);

        var dataObject = qs.stringify({
            'username': data.username,
            'password': data.password
        });

        var config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        };

        return new Promise(async (resolve, reject) => {
            api.post('/auth/login', dataObject, config)
                .then(response => {
                    setToken(response?.data?.access_token);
                    setUser(response.data);
                    localStorage.setItem('@userInfo', JSON.stringify(response.data))
                    // set the user token in the async storage
                    localStorage.setItem('@userToken', response?.data?.access_token)
                    resolve(response);

                })
                .catch(error => {
                    reject(error);
                    errorInterceptor(error);
                    setIsLoading(false);
                });
        });

    }

    const signUp = (data) => {
        return new Promise((resolve, reject) => {
            api.post('/auth/register', data)
                .then(response => {
                    setToken(response?.data?.access_token);
                    setUser(response.data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    const logout = () => {
        console.log("Logout")
        setIsLoading(true);
        setUserToken(null);
        localStorage.removeItem('@userToken')
        localStorage.removeItem('@userInfo')
        setIsLoading(false);
        // go to login page by reloading the page
        window.location.href = '/sign-in';
    };

    const handleApiErrorResponse = useCallback((error) => {
        if (error.response) {
            switch (error.response.status || 0) {
                case 401:
                    setUser(null);
                    setToken(null);
                    logout();
                    break;
                case 403:
                    break;
                case 404:
                    logout();
                    break;
                case 500:
                    logout();
                    break;
                default:
                    break;
            }
        }
    }, []);

    useRollbarPerson(user);
    setApiToken(token);
    setHandler(handleApiErrorResponse);


    const isLoggedIn = () => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            try {
                const userToken = localStorage.getItem('@userToken');
                const userInfo = localStorage.getItem('@userInfo');

                if (userToken && userInfo) {
                    setUser(JSON.parse(userInfo));
                    setToken(userToken);
                }
                setIsLoading(false);
                resolve();
            } catch (error) {
                setIsLoading(false);
                reject();
            }
        });
    };



    React.useEffect(() => {
        isLoggedIn();
    }, [])

    // update user info in the context and async storage using data from the edit profile screen
    const refreshUserInfo = async (data) => {
        setUser(data);
        await localStorage.setItem('@userInfo', JSON.stringify(data))
        // if user token exists in the data then update the user token in the context and async storage
        if (data && data.access_token) {
            setUserToken(data.access_token);
            await localStorage.setItem('@userToken', data.access_token)
        }
    };

    // update profile and update the user info in the context and async storage
    const updateProfile = async (data) => {
        setIsLoading(true);

        var config = {
            method: 'post',
            url: `/users/profile/update`,
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            data: data
        };

        axios(config).then(async (response) => {
            let userInfo = response.data
            setUser(userInfo);
            await localStorage.setItem('@userInfo', JSON.stringify(userInfo))
            setIsLoading(false);
            // toast.success({ message: 'Profile updated successfully' });
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });

    }


    return {
        login,
        logout,
        isLoading,
        userToken,
        updateProfile,
        refreshUserInfo,
        user,
        errorPage,
        updateErrorPage,
        baseURL,
        updateUser,
        signUp,
        postRequest,
        getRequest,
        putRequest,
        deleteRequest,
    }

}



