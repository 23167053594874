import React, { useEffect } from 'react';

const CountDownTimer = ({ isResendEnable, setResendEnabled }) => {

    const [timerCount, setTimer] = React.useState(59);
    const activator = Math.random();

    useEffect(() => {
        let interval;
        if (isResendEnable) {
            interval = setInterval(() => {
                if (timerCount > 0) {
                    setTimer(lastTimerCount => {
                        lastTimerCount <= 1 && clearInterval(interval);
                        return lastTimerCount - 1;
                    });
                }
            }, 1000);

        }

        return () => {
            if (isResendEnable) {
                clearInterval(interval);
            }
        };
    }, [activator,  isResendEnable, timerCount]);

    useEffect(() => {
        if (timerCount === 0) {
            setResendEnabled()
        }
    }, [timerCount,  setResendEnabled])


    return (
        <font className="text-muted"> Resend in {timerCount} sec </font>
    )
}

export default CountDownTimer