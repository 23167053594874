import axios from "axios";

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
});

let handleApiErrorResponse = () => { }

api.defaults.headers.common['Accept'] = 'application/json';
api.defaults.headers.common['Content-Type'] = 'application/json';
api.defaults.headers.common['Referrer-Policy'] = 'strict-origin-when-cross-origin';
// get token from localStorage
const userToken = localStorage.getItem('@userToken');
if (userToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
}

const postRequest = (endpoint, payload, config) => {
    const customConfig  = {...config}
    return new Promise((resolve, reject) => {
        api.post(endpoint, payload, customConfig)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                handleApiErrorResponse(error);
                reject(error);
            });
    });
}

const getRequest = (endpoint, params, options = {}) => {
    const source = axios.CancelToken.source();

    const request = new Promise((resolve, reject) => {
        api.get(endpoint, {
            params: params != null ? params : {},
            cancelToken: source.token,
            ...options
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            if (axios.isCancel(error)) {
                reject();
            } else {
                handleApiErrorResponse(error);
                reject(error);

            }
        });
    });

    request.abort = function () {
        source.cancel();
    }

    return request;

}

const putRequest = (endpoint, payload) => {
    return new Promise((resolve, reject) => {
        api.put(endpoint, payload)
            .then(response => {
                resolve(response);
            }).catch(error => {
                handleApiErrorResponse(error);
                reject(error);
            });
    });
}

const deleteRequest = (endpoint) => {
    return new Promise((resolve, reject) => {
        api.delete(endpoint)
            .then(response => {
                resolve(response);
            }).catch(error => {
                handleApiErrorResponse(error);
                reject(error);
            });
    });
}


const setHandler = (handler) => handleApiErrorResponse = handler;
const setToken = (token) => api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default api;

export {
    postRequest,
    getRequest,
    putRequest,
    deleteRequest,
    setHandler,
    setToken
};