import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, Stack } from 'react-bootstrap'
// import CustomInput from '../components/CustomInput'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { useForm } from 'react-hook-form'
import api from '../core/api'
import { errorInterceptor } from '../core/errorInterceptor'
import { ColorRing } from 'react-loader-spinner'
import { Link } from 'react-router-dom'

export default function News(props) {

    const { watch } = useForm(
        {
            defaultValues: {
                search: '',
            }
        }
    );

    let search = watch('search');

    const [articlesPicUrl] = useState(`${process.env.REACT_APP_API_URL}/articles/download/file/path/`);
    const [defaultPicUrl] = useState(`${require('../assets/placeholder-image.png')}`);

    const [articles, setArticles] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [featuredArticle, setFeaturedArticle] = React.useState(undefined);
    const [latestArticles, setLatestArticles] = React.useState([]);

    const getArticles = async () => {
        return new Promise(async (resolve, reject) => {
            api.get(`/articles/active/`)
                .then(response => {
                    resolve(response)
                    console.log(response.data)
                    setArticles(response.data.articles)
                    setFeaturedArticle(response.data.featured_article)
                    setLatestArticles(response.data.latest_articles)
                    setIsLoading(false)
                }
                )
                .catch(error => {
                    reject(error)
                    errorInterceptor(error);
                    setIsLoading(false)
                })
        })

    }


    useEffect(() => {
        getArticles();
        // eslint-disable-next-line
    }, [])

    const filterArticles = () => {
        setIsLoading(true)
        // filter articles by title
        let filteredArticles = articles.filter((article) => {
            return article.title.toLowerCase().includes(search.toLowerCase())
        })

        setArticles(filteredArticles)
        setIsLoading(false)
    }

    useEffect(() => {
        filterArticles();
        // eslint-disable-next-line
    }, [search])

    const timeAgo = (date) => {
        // example date format: 2023-10-31T14:35:18
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);

        let interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }

        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }

        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }

        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }

        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }

        return Math.floor(seconds) + " seconds ago";
    }

    // function to strip html tags from string
    const stripHtml = (html) => {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }


    return (
        <>
            <Header link={props.link} />
            <main>
                <SubHeader title={'News'} description={'This page is dedicated to providing you with the latest and most relevant news related to our organization, industry, and community. Our goal is to keep you informed and up-to-date on important events, developments, and trends that may impact you and Kids Alert. Whether you\'re interested in learning more about Kids Alert, staying up-to-date on the latest news, or simply want to stay informed about what\'s happening in our community, you\'ll find it all here. '} />
                <Container className='mb-4'>
                    {isLoading &&
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}><ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /></div>}
                    {!isLoading &&
                        <>
                            <Row style={{ marginTop: 80 }}>
                                <h1 className='sub-title' style={{ marginBottom: 20 }}>Latest News</h1>
                                <Col md={6} lg={6}>
                                    {featuredArticle &&
                                        <Card className='news-featured-section' style={{ height: '95%', width: '100%', border: 'none' }}>
                                            <img variant="top" alt="featured" className='news-featured-image' src={featuredArticle.attachment_id ? articlesPicUrl + featuredArticle.attachment_id : defaultPicUrl} />
                                            <div className="top-left">Latest Story</div>
                                            <div className="background-for-title bottom-right">
                                                <Link to={`/news/${featuredArticle.uuid}`} style={{ textDecoration: 'none' }} className='text-decoration-none text-white'>
                                                    {featuredArticle.title}
                                                </Link>
                                            </div>
                                        </Card>
                                    }
                                </Col>

                                <Col>
                                    <Stack gap={3}>
                                        {latestArticles && latestArticles.map((article, index) => (
                                            <Row key={index}>
                                                <Col md={5} lg={5}>
                                                    <Card className='news-featured-section-small' style={{ width: '100%', border: 'none' }}>
                                                        <img variant="top" alt="featured" className='news-featured-image-small' src={article.attachment_id ? articlesPicUrl + article.attachment_id : defaultPicUrl} />
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <p className='news-date text-gray'>{timeAgo(article.created_at)}</p>
                                                    <p className='news-description-small text-gray'>
                                                        <Link to={`/news/${article.uuid}`} style={{ textDecoration: 'none' }} className='text-decoration-none text-main'>
                                                            {article.title}
                                                        </Link>
                                                    </p>
                                                    <p className='news-description-extra-small text-dark'>{stripHtml(article.description).substring(0, 80)}...</p>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Stack>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 50 }}>
                                <h1 className='sub-title' style={{ marginBottom: 20 }}>Other News</h1>
                                <Row>
                                    {articles && articles.map((article, index) => (
                                        <Col md={3} lg={3} key={index}>
                                            <Card className='news-featured-section-small' style={{ width: '17rem', border: 'none' }}>
                                                <img variant="top" alt="featured" className='news-featured-image-small' src={article.attachment_id ? articlesPicUrl + article.attachment_id : defaultPicUrl} />
                                                <p className='news-description text-gray'>
                                                    <Link to={`/news/${article.uuid}`} style={{ textDecoration: 'none' }} className='text-decoration-none text-main'>
                                                        {article.title}
                                                    </Link>
                                                </p>
                                                <p className='news-description-extra-small text-dark'>{stripHtml(article.description).substring(0, 60)}...</p>
                                                <p className='news-date-small text-gray'>{timeAgo(article.created_at)}</p>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Row>
                        </>}
                </Container>
            </main>
            <Footer />
        </>
    )
}
