import React from 'react'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'

function PrivacyPolicy(props) {
    return (
        <>
            <Header link={props.link} />
            <main>
                <SubHeader title={'Privacy Policy'} description={'All defined privacy policies used below shall have the meanings set forth in Our Terms and Conditions. See Terms and Conditions.'} />
                <Container>
                    <Row style={{ marginTop: 50 }}>
                        <p className='description'>This Privacy Policy (“Policy”) explains how information about You is collected, used, and disclosed by Your access or use of this Site or otherwise as a result of Your interactions with Us or when you use our KidsAlert mobile app (the "App").
                            By visiting this Site directly or through another site, You accept the terms and conditions of this Policy.
                            This Policy applies to this Site or the KidsAlert mobile app.
                            We are not responsible for the content or privacy practices on any website not operated by Us to which this Site links or that links to this Site.
                            We respect children’s privacy. We do not knowingly or intentionally collect personal information from children under age 16.
                            Elsewhere on the Site, you have represented and warranted that you are either 16 years of age or using the Site with the supervision of a parent or guardian.
                            If you are under the age of 16, please do not submit any personal information to Us, and rely on a parent or guardian to assist you.
                            Information Collection – We collect information from you in several different ways on this Site.</p>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Registration</h1>
                        <ul>
                            <li className="description">
                                Before using certain portions of this Site or the App, you may be required to complete an online registration form.
                                During registration, you will be asked to provide to Us certain personal information, including but not limited to your name, phone number and email address.
                                In addition, We may also ask you for your address of residence and/or your organization’s address of operation, so we can comply with applicable laws and regulations.
                                These kinds of personal information are used to communicate with you about the Sites or the App.
                                If We encounter a problem when using the Site or the App, your personal information may be used to contact you.
                            </li>
                        </ul>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Information We Collect Automatically</h1>
                        <p className='description'>
                            We may also automatically collect information about you when you access or use the Site or the App, including:
                            Log Information: We obtain information about your use of Our websites, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to this Site.
                            Device Information: We collect information about the computer or mobile device you use to access Our Services, such as the hardware model, operating system and version, unique device identifiers, mobile network information, and browsing behavior.
                            Location Information: We may collect information about the precise location of your device when you consent to the collection of this information. We may also collect information about your approximate location each time you access this Site or the App.
                            Information Collected by Cookies and other Tracking Technologies: We may use cookies, web beacons, and other tracking technologies to collect information about you and your interaction with this Site, including information about your browsing behavior, purchase behavior, and other engagement with the Services.
                            We use this information in one or more of the ways described in the “Use of Information” section below.
                            Most web browsers are set to accept cookies by default, but you can usually change your browser settings to remove or reject cookies.
                            Information We Collect from Other Sources. We may also receive information about you from other sources and combine or link that with information We have about you.
                            For example, We may collect demographic and change-of-address information from third party sources and information from third party social media platforms (such as Facebook) if you log into this Site using your social media account credentials or if you make certain content and information publicly available, such as photos, videos, and profile information.
                        </p>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Information Use and Disclosure</h1>
                        <h4 className='sub-title' style={{ marginBottom: 10 }}>Internal Use</h4>
                        <p className='description'>
                            We use your personal information to process your account.
                            We may internally use your personal information to improve this Site’s or App's content and layout, to improve outreach and for Our own awareness efforts (including making aware our services to you), and to determine general information about visitors to this Site or the App.
                            Communications with You We will use your personal information to communicate with you about this Site or the App.
                            Also, We may send you a confirmation email when you register with Us. We may send you a service-related announcement on the rare occasions when it is necessary (for example, if we must temporarily suspend our service for maintenance).
                            Also, you may submit your email address for reasons such as to register for a contest or sweepstakes or to sign up for email newsletters and special offers.
                            If you submit your email address, We use it to deliver the information to you.
                            We always permit you to unsubscribe or opt out of future emails.
                            Because We have to communicate with you about our services, you cannot opt out of receiving emails related to your orders.
                        </p>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <h4 className='sub-title' style={{ marginBottom: 10 }}>External Use</h4>
                        <p className='description'>
                            Except as otherwise set forth below, We do not sell, rent, trade, license or otherwise disclose your specific personal information to anyone.
                            We may disclose information to third parties that perform specific functions on Our behalf.
                            We may disclose personal information or financial information in response to requests from law enforcement officials conducting investigations; subpoenas; a court order; or if We are otherwise required to disclose such information by law.
                            We also will release personal information where disclosure is necessary to protect our legal rights, enforce our Terms and Conditions or other agreements, or to protect ourselves or others.
                            For example, We may share information to reduce the risk of fraud or if someone uses or attempts to use this Site or the App for illegal reasons or to commit a crime.
                            While We will not sell (or trade or rent) personally identifiable information to other companies as part of Our regular course of business.
                            However, it’s possible that We might acquire or merge with or be acquired by another company or that We might dispose of some or all of Our assets.
                            If that happens, your personal information may be disclosed to another company, but that disclosure will be subject to the Privacy Policy in effect.
                            We may share non-personal information (such as the number of daily visitors to a particular web page) with third parties such as advertising partners.
                            This information does not directly personally identify you or any user.
                        </p>
                    </Row>

                    <Row style={{ marginTop: 20, marginBottom: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Data Security</h1>
                        <p className='description'>We take reasonable measures, including administrative, technical, and physical safeguards, to protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.</p>
                    </Row>

                    <Row style={{ marginTop: 20, marginBottom: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Opt Out/Corrections</h1>
                        <p className='description'>
                            Upon your request, We will
                            (a) correct or update your personal information;<br/>
                            (b) stop sending emails to your email address; and/or<br/>
                            (c) disable your account to prevent any future purchases through that account. You can make these requests by emailing Us at support@kidsalert.co.zw. Please do not email sensitive information.

                        </p>
                    </Row>

                    <Row style={{ marginTop: 20, marginBottom: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Offline Collection Use and Disclosure</h1>
                        <p className='description'>
                        We also may collect information offline. 
                        We will treat any information collected offline in a manner that is consistent with this Policy. 
                        One example involves someone calling Us to place an order or to ask questions. 
                        When someone calls, We will ask only for the personal information We need in order to place the order or to answer the question. 
                        When We need to store information (such as order information), We will enter it into our database.
                        </p>
                    </Row>

                    <Row style={{ marginTop: 20, marginBottom: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Updates to this Policy</h1>
                        <p className='description'>
                        If We change or update this Privacy Policy, We will post changes and updates on the Site so that you will always be aware of what information We collect, use and disclose. We encourage you to review this Policy from time to time so you will know if the Privacy Policy has been changed or updated. If you have any questions about the Policy, please contact us at support@kidsalert.co.zw.

                        </p>
                    </Row>
                </Container>
            </main>

            <Footer />
        </>
    )
}

export default PrivacyPolicy