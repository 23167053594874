import React, { useState } from 'react'
import Header from '../components/Header'
import { Container, Row } from 'react-bootstrap'
import CustomInput from '../components/CustomInput'
import { useForm } from 'react-hook-form'
import Label from '../components/Label'
import CustomButton from '../components/CustomButton'
import { AuthContext } from '../context/AuthContext'
import { ColorRing } from 'react-loader-spinner'
import toastr from '../core/toast'
import { errorInterceptor } from '../core/errorInterceptor'
import api from '../core/api'

function ChangePassword(props) {
  const { logout } = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  // react hook form for form validation
  const { control, handleSubmit, formState: { errors }, reset,  watch } = useForm(
    {
      defaultValues: {
        old_password: '',
        new_password: '',
        password_repeat: '',
      }
    }
  );

  const pwd = watch('new_password')

  // handle form submit
  const onSubmit = async (data) => {
    console.log(data)
    setIsLoading(true);

    return new Promise(async (resolve, reject) => {
      api.post('/users/password/change', data)
        .then(response => {
          resolve(response.data);
          reset()
          toastr.success(response?.data?.detail?.message);
          setIsLoading(false);
        }).catch(error => {
          reject(error);
          if (error.response.status !== 401) {
            errorInterceptor(error)
          }
          if (error.response.status === 401) {
            logout()
          }
          setIsLoading(false);
        })
    })
  }

  return (
    <>
      <Header link={props.link} />
      <main>
        <Container style={{
          marginBottom: 100,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 600,
        }}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Row style={{ marginTop: 50 }}>
              <h1 className='sub-title' style={{ marginBottom: 10 }}>Change Password</h1>
              <p className='description'>
                To change your password, please enter your current password and your new password below.
              </p>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <Label forInput="old_password" value="Current Password" />
                <CustomInput
                  name="old_password"
                  control={control}
                  placeholder="Current Password"
                  type={"password"}
                  errors={errors.old_password}
                  rules={{
                    required: 'Password is required',
                    minLength: {
                      value: 3,
                      message: 'Password should have a minimum of 3 characters'
                    }
                  }} />
              </div>
              <div style={{ marginBottom: 10 }}>
                <Label forInput="new_password" value="New Password" />
                <CustomInput
                  name="new_password"
                  control={control}
                  placeholder="New Password"
                  type={"password"}
                  errors={errors.new_password}
                  rules={{
                    required: 'Password is required',
                    minLength: {
                      value: 3,
                      message: 'Password should have a minimum of 3 characters'
                    }
                  }} />
              </div>
              <div style={{ marginBottom: 10 }}>
                <Label forInput="password_repeat" value="Confirm Password" />
                <CustomInput
                  name="password_repeat"
                  control={control}
                  placeholder="Confirm Password"
                  type={"password"}
                  errors={errors.password_repeat}
                  rules={{
                    required: 'Password Confirmation is required',
                    validate: value => value === pwd || 'Passwords do not match'
                  }} />
              </div>
            </Row>
            <div style={{ marginTop: 10 }} className='text-left text-white py-3'>
              <div className='mb-4'>
                {!isLoading && <CustomButton fullWidth={true} className='primary-button' role='button'>
                  Update Password
                </CustomButton>}
                {isLoading &&
                  <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                    <ColorRing
                      visible={true}
                      height="50"
                      width="50"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    />
                  </CustomButton>
                }
              </div>
              <div>
                <CustomButton className='primary-button-outline' fullWidth={true} href='#!' role='button'>
                  Cancel
                </CustomButton>
              </div>
            </div>
          </form>
        </Container>
      </main>
    </>

  )
}

export default ChangePassword