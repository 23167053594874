import React from 'react';

export default function CustomSelect({
    type = 'text',
    name,
    value,
    placeholder,
    className,
    autoComplete,
    required,
    isFocused,
    handleChange,
    id,
    disabled,
    min,
    max,
    maxLength,
    selectedOption,
    options
}) {

    return (
        <div className="">
            <select
                name={name}
                value={selectedOption}
                className='select-control'
                onChange={(e) => handleChange(e)}
                required={required}
                id={id}
                disabled={disabled}
            >
                <option value="">Select {placeholder}</option>
                {options.map(option => (
                    <option key={option.key} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}
