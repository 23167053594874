import React, { useEffect } from 'react'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { Card, Container } from 'react-bootstrap'
import Footer from '../components/Footer'
import { errorInterceptor } from '../core/errorInterceptor'
import { ColorRing } from 'react-loader-spinner'
import api from '../core/api'

function Organisations(props) {

  const [organisations, setOrganisations] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getOrganisations = async () => {
    return new Promise(async (resolve, reject) => {
      api.get(`/organisations/active/`)
        .then(response => {
          resolve(response)
          setOrganisations(response.data)
          setIsLoading(false)
        }
        )
        .catch(error => {
          reject(error)
          errorInterceptor(error);
          setIsLoading(false)
        })
    })

  }

  useEffect(() => {
    getOrganisations();
  }, [])


  return (
    <>
      <Header link={props.link} />
      <main>
        <SubHeader title={'Organisations'} description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'} />
        <Container>
          {isLoading &&
            <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}><ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /></div>}
          {!isLoading &&
            <>{organisations.length === 0 &&
              <>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}>
                  <Card border="light" style={{ border: 'none' }}>
                    <Card.Body className="text-center">
                      <i className="bi bi-emoji-frown" style={{ fontSize: "10rem" }} />

                      <Card.Text className='description'>
                        No results found
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </>
            }
              {organisations.length > 0 &&
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4" style={{ marginTop: 50, marginBottom: 50 }}>
                  {organisations.map((organisation, index) => (
                    <div className="col" key={index}>
                      <div className="card h-100">
                        <div className="card-body">
                          <h5 className="card-title text-main">{organisation.name}</h5>
                          <p className="card-text text-gray-small">Address: {organisation.address}</p>
                          <p className="card-text text-gray-small">Country: {organisation.country?.name}</p>
                          <p className="card-text text-gray-small">Email: {organisation?.contact?.primary_email}</p>
                          <p className="card-text text-gray-small">Phone: {organisation?.contact?.primary_phone}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </>}


        </Container>
      </main>
      <Footer />
    </>
  )
}

export default Organisations