import React from 'react'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'

function TermsAndConditions(props) {
    return (
        <>
            <Header link={props.link} />
            <main>
                <SubHeader title={'Terms & Conditions'} description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'} />
                <Container>
                    <Row style={{ marginTop: 50 }}>
                        <Col>
                            <h1 className="sub-title">Introduction</h1>
                            <p className="description">
                                Welcome to the Amber Alert App and Website (the "Service"). The Service is operated by XYZ Company ("us", "we", or "our"). By accessing or using the Service, you agree to be bound by these Terms and Conditions ("Terms").
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col>
                            <h1 className="sub-title">Privacy Policy</h1>
                            <p className="description">
                                Our Privacy Policy outlines how we collect, use, and disclose personal information. By using the Service, you also agree to our Privacy Policy.
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col>
                            <h1 className="sub-title">User Conduct</h1>
                            <p className="description">
                                You agree to use the Service only for lawful purposes and in a way that does not infringe on the rights of others or restrict their use and enjoyment of the Service. You may not use the Service to harass, abuse, or harm others or to post, share, or transmit content that is illegal, obscene, or defamatory.
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col>
                            <h1 className="sub-title">Intellectual Property</h1>
                            <p className="description">
                                The Service and its original content, features, and functionality are owned by us and are protected by international copyright,
                                trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col>
                            <h1 className="sub-title">Disclaimer of Warranties</h1>
                            <p className="description">
                                The Service is provided "as is" and without warranties of any kind, whether express or implied. We do not warrant that the Service will be uninterrupted, error-free, or free from viruses or other harmful components.
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col>
                            <h1 className="sub-title">Limitation of Liability</h1>
                            <p className="description">
                                We shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues,
                                whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service, (ii) any conduct or content of any third party on the Service, or (iii) unauthorized access, use, or alteration of your transmissions or content.
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 50 }}>
                        <Col>
                            <h1 className="sub-title">Governing Law and Jurisdiction</h1>
                            <p className="description">
                                These Terms shall be governed by and construed in accordance with the laws of the State of California, without giving effect to any principles of conflicts of law. You agree to submit to the personal jurisdiction of the state and federal courts located in California for the resolution of any disputes.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    )
}

export default TermsAndConditions