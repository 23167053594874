import React, { useState } from 'react'
import Header from '../components/Header'
import { Col, Container, Row } from 'react-bootstrap'
import CustomInput from '../components/CustomInput'
import { useForm } from 'react-hook-form'
import Label from '../components/Label'
import CustomButton from '../components/CustomButton'
import { Link, useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import axios from 'axios'
import { AuthContext } from '../context/AuthContext'
import { ColorRing } from 'react-loader-spinner'

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9]+)*$/

export default function Register(props) {

    const navigate = useNavigate();

    const { refreshUserInfo } = React.useContext(AuthContext)
    const [phoneError, setPhoneError] = useState(null);
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // react hook form for form validation
    const { control, handleSubmit, formState: { errors }, watch } = useForm(
        {
            defaultValues: {
                first_name: '',
                last_name: '',
                email: "",
                password: "",
                password_repeat: "",
                phone_number: ""
            }
        }
    );


    const pwd = watch('password')

    const onSubmit = (data) => {

        console.log(data)
        setIsLoading(true);

        // check if phone number is valid
        if (phone === null) {
            setPhoneError('Phone number is required')
            setIsLoading(false);
            return
        }

        data.phone_number = phone
        data.username = data.email
        delete data.password_repeat


        let user_data = { ...data}
        setIsLoading(false);

        axios.post(`/users/register/new`, user_data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async (response) => {
            console.log(response.data)
            let userInfo = response.data
            refreshUserInfo(userInfo)
            setIsLoading(false);
            navigate('/confirm-account', { state: { email: data.email, phone_number: data.phone_number }})
        }).catch((error) => {
            console.log(error)
            setIsLoading(false);
        });
    }

    return (

        <>
            <Header link={props.link} />
            <main>
                <Container style={{
                    marginBottom: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 700,
                }}>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row style={{ marginTop: 50 }}>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Create Account</h1>
                            <p className='description'>Please fill out the form below to create a new account.</p>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col style={{ marginBottom: 20 }} md={6}>
                                <Label forInput="first_name" value="First Name" />
                                <CustomInput
                                    control={control}
                                    name="first_name"
                                    placeholder={'First Name'}
                                    errors={errors.first_name}
                                    rules={{ required: "First Name is required" }}
                                />
                                {errors.first_name?.type === 'required' && <p role="alert" className='error-text'>First Name is required</p>}
                            </Col>
                            <Col style={{ marginBottom: 20 }} md={6}>
                                <Label forInput="last_name" value="Last Name" />
                                <CustomInput
                                    control={control}
                                    name="last_name"
                                    placeholder={'Last Name'}
                                    errors={errors.last_name}
                                    rules={{ required: "Last Name is required" }}
                                />
                                {errors.last_name?.type === 'required' && <p role="alert" className='error-text'>Last Name is required</p>}
                            </Col>
                            <Col style={{ marginBottom: 20 }} md={6}>
                                <Label forInput="email" value="Email Address" />
                                <CustomInput
                                    control={control}
                                    name="email"
                                    placeholder={'Email Address'}
                                    errors={errors.email}
                                    rules={{
                                        required: "Email is required",
                                        pattern: {
                                            value: EMAIL_REGEX,
                                            message: 'Email is invalid'
                                        }
                                    }}
                                />
                                {errors.email?.type === 'required' && <p role="alert" className='error-text'>Email Address is required</p>}
                            </Col>
                            <Col style={{ marginBottom: 20 }} md={6}>
                                <Label forInput="phone_number" value="Phone Number" />
                                <PhoneInput
                                    className="input-control"
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={setPhone}
                                    country="ZW"
                                    defaultCountry='ZW'
                                />
                                {phoneError && <p role="alert" className='error-text'>{phoneError}</p>}
                            </Col>
                            <Col style={{ marginBottom: 20 }} md={6}>
                                <Label forInput="password" value="Password" />
                                <CustomInput
                                    control={control}
                                    name="password"
                                    placeholder={'Password'}
                                    type='password'
                                    errors={errors.password}
                                    rules={{ required: "Password is required" }} />
                                {errors.password?.type === 'required' && <p role="alert" className='error-text'>Password is required</p>}
                            </Col>
                            <Col style={{ marginBottom: 20 }} md={6}>
                                <Label forInput="password_repeat" value="Confirm Password" />
                                <CustomInput
                                    control={control}
                                    name="password_repeat"
                                    placeholder={'Confirm Password'}
                                    type='password'
                                    errors={errors.password_repeat}
                                    rules={{
                                        required: 'Password Confirmation is required',
                                        validate: value => value === pwd || 'Passwords do not match'
                                    }}/>
                                {errors.password_repeat?.type === 'required' && <p role="alert" className='error-text'>Confirm Password is required</p>}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }} className='text-left text-white py-3'>
                            <Col>
                                {!isLoading && <CustomButton fullWidth={true} className='primary-button' href='#!' role='button'>
                                    Register
                                </CustomButton>}
                                {isLoading &&
                                    <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                                        <ColorRing
                                            visible={true}
                                            height="50"
                                            width="50"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                        />
                                    </CustomButton>}
                            </Col>
                            <Row style={{ marginTop: 20 }} className='text-center'>
                                <font className='link-text'>I already have an account. <Link to={'/sign-in'} className='link text-danger'>Login</Link></font>
                            </Row>
                        </Row>
                    </form>
                </Container>
            </main>
        </>

    )
}
