import React, { useEffect } from 'react'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import { ColorRing } from 'react-loader-spinner'
import api from '../core/api'
import { errorInterceptor } from '../core/errorInterceptor'
import CustomInput from '../components/CustomInput'
import { useForm } from 'react-hook-form'

function PoliceStations(props) {

  const [policeStations, setPoliceStations] = React.useState(undefined);
  const [unfilteredStations, setUnfilteredStations] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { control, watch } = useForm(
    {
      defaultValues: {
        search: '',
      }
    }
  );

  let search = watch('search');

  const getPoliceStations = async () => {
    return new Promise(async (resolve, reject) => {
      api.get(`/stations/active/`)
        .then(response => {
          resolve(response)
          setPoliceStations(response.data)
          setUnfilteredStations(response.data)
          setIsLoading(false)
          console.log(response.data)
        }
        )
        .catch(error => {
          reject(error)
          errorInterceptor(error);
          setIsLoading(false)
        })
    })

  }

  useEffect(() => {
    getPoliceStations();
  }, [])

  const filterPoliceStations = () => {
    // filter police stations by name
    let filteredStations = unfilteredStations.filter((station) => {
      return station.name.toLowerCase().includes(search.toLowerCase())
    })

    setPoliceStations(filteredStations)
  }

  useEffect(() => {
    if (search.length > 3) {
      filterPoliceStations();
    }
    if (search.length === 0) {
      setPoliceStations(unfilteredStations)
    }
    // eslint-disable-next-line
  }, [search])


  return (
    <>
      <Header link={props.link} />
      <main>
        <SubHeader title={'Police Stations'} description={'These are the police stations that are registered on the platform. If you do not find the police station you are looking for, please contact us at support@kidsalert.co.zw to register the police station. If you are a police station and would like to register on the platform, please contact us at the same email address.'} />
        <Container>
          <Row style={{ marginTop: 50, marginBottom: 20 }} id='search'>
            <p className='description'>
              Search for a police station to report a missing child or to report a child in danger. The details of the police station will be displayed below. The police station will be notified of the report and will be able to view the report on their dashboard.
            </p>
          </Row>
          <Row className='align-items-center'>
            <Col className='text-start'>
              <CustomInput
                control={control}
                type='text'
                name='search'
                placeholder={'Type police station name to search'}
              />
            </Col>
            <Col>
            </Col>
          </Row>

          {isLoading &&
            <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}><ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /></div>}
          {!isLoading &&
            <>{policeStations && policeStations.length === 0 &&
              <>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}>
                  <Card border="light" style={{ border: 'none' }}>
                    <Card.Body className="text-center">
                      <i className="bi bi-emoji-frown" style={{ fontSize: "10rem" }} />

                      <Card.Text className='description'>
                        No results found
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </>
            }
              {policeStations && policeStations.length > 0 &&
                <div className="card-list" style={{ marginTop: 50, marginBottom: 50 }}>
                  {policeStations.map((station, index) => (
                    <Card key={index} style={{ marginBottom: '20px' }}>
                      <Card.Body>
                        <h5 className="card-title text-main">{station.name}</h5>
                        <p className="card-text text-gray-small">{station.address}</p>
                        {station.station_contacts && station.station_contacts.map((contact, childIndex) => (
                          <div key={childIndex}>
                            <hr />
                            <p className="card-text text-gray-small">Contact: {contact.contact?.first_name} {contact.contact?.last_name}</p>
                            <p className="card-text text-gray-small">Email: {contact?.contact.primary_email}</p>
                            <p className="card-text text-gray-small">Phone: {contact.contact?.primary_phone}</p>
                          </div>
                        ))}

                      </Card.Body>
                    </Card>
                  ))}
                </div>
              }
            </>}
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default PoliceStations