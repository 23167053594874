import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { Link } from 'react-router-dom'

export default function Help(props) {
    return (
        <>
            <Header link={props.link} />
            <main>
                <SubHeader title={'Help'} description={'The program is designed to promote collaboration among community members, law enforcement agencies, and other stakeholders to help keep children safe and prevent abductions. The program is based on the principle that "We are Each other\'s Eyes," meaning that everyone in the community has a role to play in keeping children safe. By working together and keeping a watchful eye on our surroundings, we can identify potential risks and take steps to prevent harm from coming to our children.'} />
                <Container>
                    <Row style={{ marginTop: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>How to use Kids Alert</h1>
                        <p className='description'>This app is available on both Android and iOS. You can find the app on the App Store or Google Play. You can also download the app from the App Store or Google Play.
                            To use Kids Alert you need to create an account. You can create an account by clicking on the <Link to={'/register'} className='link text-main'>Register</Link> link on the top right corner of the page. If you already have an account you can login by clicking on the <Link to={'/login'} className='link text-main'>Login</Link> link on the top right corner of the page.
                            Once you have logged in you can add your report by clicking on the <Link to={'/submit-a-report'} className='link text-main' >Submit a Report</Link> link on the home page.
                        </p>
                    </Row>

                    <Row style={{ marginTop: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Where to report</h1>
                        <p className='description'>Report a crime or disappearance of a child to the nearest police station. Reporting the incident to the police station can help to initiate a prompt and effective response to the situation, which can make a significant difference in recovering a missing child or preventing a crime from taking place.</p>
                    </Row>

                    <Row style={{ marginTop: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>How to post a missing child</h1>
                        <p className='description'>To post a missing child on the application, follow these steps:</p>
                        <ol>
                            <li>Click on the <Link to={'/submit-a-report'} className='link text-main'>Submit a Report</Link> link on the home page.</li>
                            <li>Fill out the required information about the missing child, including their name, age, gender, height, weight, and any distinguishing features or marks.</li>
                            <li>Upload a recent photo of the missing child, along with any additional photos that may be helpful in identifying them.</li>
                            <li>Provide information about when and where the child was last seen, as well as any other relevant details about their disappearance.</li>
                            <li>Submit the information and photos to the system for review and distribution.</li>
                        </ol>
                        <p className='description'>Once the information has been submitted, it will be reviewed by the system administrators and shared with law enforcement and other relevant organizations to help in the search for the missing child.</p>
                        <br />
                        <p className='description'>It is important to ensure that all information provided is accurate and up-to-date to increase the chances of finding the missing child. Additionally, it is important to ensure that proper consent has been obtained from the child's parents or guardians before posting their information on the application.</p>
                    </Row>

                    <Row style={{ marginTop: 50, marginBottom: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Facial Recognition Feature</h1>
                        <p className='description'>
                            The facial recognition feature can be used in the missing kids application to help identify missing children. When a child goes missing, their photo can be uploaded into the system. The facial recognition feature can then be used to compare the missing child's photo against photos of children who have been found or identified by law enforcement or other sources.
                            <br /><br />
                            This can help speed up the identification process and increase the chances of finding the missing child. It can also help prevent cases of mistaken identity, where a child may be misidentified as someone else.
                            <br /><br />
                            However, it is important to ensure that the use of facial recognition technology is carried out ethically and with the utmost care for the privacy and rights of the individuals involved. This includes obtaining proper consent, ensuring data security and privacy, and implementing safeguards against bias and discrimination.
                            <br /><br />
                            Overall, the facial recognition feature can be a useful tool in the search for missing children, but it must be implemented responsibly and with careful consideration of its potential impact.
                        </p>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    )
}
