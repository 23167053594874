import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import logo from '../assets/kidsalert-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

function Footer() {
    return (
        <footer className='footer-container'>
            <Container>
                <Row>
                    <Col className='text-center text-white py-3'>
                        <Row style={{ marginBottom: 10 }}>
                            <Col>
                                <FontAwesomeIcon icon={brands('facebook')} size={'3x'} className="footer-icon" />
                                <FontAwesomeIcon icon={brands('twitter')} size={'3x'} className="footer-icon" />
                                <FontAwesomeIcon icon={brands('instagram')} size={'3x'} className="footer-icon" />
                            </Col>
                        </Row>
                        <h1> <img
                            src={logo}
                            height="55"
                            className="d-inline-block align-top"
                            alt="Kids Alert"
                        /></h1>
                        <p>&copy; {(new Date().getFullYear())} KidsAlert</p>
                        <p className='text-break'>Number 20, 6th Avenue Mabelreign, Harare, Zimbabwe All rights reserved.<br />
                        </p>

                        <Row style={{ display: 'inline', justifyContent: 'center', marginTop: 10}}>
                            <a href="/privacy-policy" className='text-break footer-link'>Privacy Policy</a> |
                            <a href="/terms-and-conditions" className='text-break footer-link'>Terms & Conditions</a> |
                            <a href="/organisations" className='text-break footer-link'>Organisations</a> |
                            <a href="/police-stations" className='text-break footer-link'>Police Stations</a>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer