import React from 'react'

function SubHeader(props) {
    const title = props.title;
    const description = props.description;

    const bgImage = {
        height: 300,
        objectFit: 'cover',
        // get image from assets folder
        backgroundImage: `url(${require('../assets/kids-alert-bg.png')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

    }
    return (
        <div className="jumbotron jumbotron-fluid bg-image" style={bgImage}>
            <div className="container" style={{ paddingTop: 80 }}>
                <div className='d-flex h-100'>
                    <div className='text-white'>
                        <h1 className='header-title'>{title}</h1>
                        <p className="description-white">{description}</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SubHeader