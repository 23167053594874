import React from 'react'
import { Link } from 'react-router-dom'

function CustomLink({ className = '', url = '', processing, children }) {
    return (
        <Link to={url}
            className={`${className}`}
            disabled={processing}
        >
            {children}
        </Link>
    )
}

export default CustomLink