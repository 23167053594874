import React, { useState, useEffect, useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import CustomButton from '../components/CustomButton'
import CustomInput from '../components/CustomInput'
import Header from '../components/Header'
import Label from '../components/Label'
import SubHeader from '../components/SubHeader'
import CustomSelect from '../components/CustomSelect'
import CustomFileInput from '../components/CustomFileInput'
import 'react-image-crop/dist/ReactCrop.css';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { AuthContext } from '../context/AuthContext'
import toastr from '../core/toast'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../constants/cropImage'
import { ColorRing } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import api from '../core/api'
import { errorInterceptor } from '../core/errorInterceptor'
import Footer from '../components/Footer'

const CROP_AREA_ASPECT = 4 / 4;

const Output = ({ croppedArea, image }) => {
    const scale = 100 / croppedArea.width;
    const transform = {
        x: `${-croppedArea.x * scale}%`,
        y: `${-croppedArea.y * scale}%`,
        scale,
        width: "calc(100% + 0.5px)",
        height: "auto"
    };

    const imageStyle = {
        transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
        width: transform.width,
        height: transform.height
    };

    return (
        <div
            className="output"
            style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
        >
            <img src={image} alt="" style={imageStyle} />
        </div>
    );
};


function NewReport(props) {

    const { user } = useContext(AuthContext)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [imageError, setImageError] = useState(null)
    const [locationError, setLocationError] = useState(null)
    const [phoneError, setPhoneError] = useState(null);
    const [selectedGender, setSelectedGender] = useState('');
    const [selectedStation, setSelectedStation] = useState('');

    const [place, setPlace] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [phone, setPhone] = useState('');

    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);


    // react hook form for form validation
    const { control, handleSubmit, formState: { errors }, setValue } = useForm(
        {
            defaultValues: {
                first_name: '',
                last_name: '',
                alias: '',
                date_of_birth: '',
                gender: '',
                date_last_seen: '',
                location_last_seen: '',
                station_id: '',
                relationship: '',
                height: '',
                weight: '',
                circumstances: '',
                skin: '',
            }
        }
    );

    // get police stations from api
    const [policeStations, setPoliceStations] = React.useState([])

    // get police stations from api using axios
    const getPoliceStations = () => {
        return new Promise((resolve, reject) => {
            api.get('/stations/active/')
                .then(response => {
                    // add the policeStation and response data to the policeStations array
                    response.data.map(station => {
                        // add key to station
                        station.key = station.id
                        // add value to station
                        station.value = station.id
                        // add label to station
                        station.label = station.name
                        return station
                    })
                    setPoliceStations(response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    errorInterceptor(error)
                    reject(error)
                })
        })
    }

    // get police stations on mount
    useEffect(() => {
        getPoliceStations()
    }, [])

    const handleSelectChange = (e) => {
        setSelectedGender(e.target.value);
    }

    const handleStationChange = (e) => {
        setSelectedStation(e.target.value);
    }

    const genders = [
        {
            key: 'MALE',
            label: 'Male',
            value: 'MALE',
        },
        {
            key: 'FEMALE',
            label: 'Female',
            value: 'FEMALE',
        }
    ];

    const handleFile = async (image) => {
        try {
            const response = await fetch(image);
            const blob = await response.blob();
            const fileName = 'example.png';
            const file = new File([blob], fileName, { type: blob.type });
            return file;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const onSubmit = async (data) => {
        // submit form  data to api
        // check if image is null

        if (phone === null) {
            setPhoneError("Upload Image", "Please upload an image")
            return
        }

        if (!data.location_last_seen) {
            setLocationError("Please select a location")
            return
        }

        setIsLoading(true)

        data.skin = "Brown"
        data.latitude = latitude
        data.longitude = longitude
        data.status = "MISSING"

        let contact = {
            first_name: user.first_name,
            last_name: user.last_name,
            primary_email: user.email,
            primary_phone: phone,
            label: data.relationship,
        }

        // convert contact to string
        data.contact = JSON.stringify(contact)

        const image = await croppedImage.then((image) => {
            if (image === null) {
                setImageError("Please upload an image")
                return
            }
            return image;

        }).catch((error) => {
            setImageError("Please upload an image")
            return
        })

        // convert image blob url to file uri 
        const file = await handleFile(image)

        // // serialize url
        let url = serializeUrl(data)
        const formData = new FormData();
        formData.append('file', file);

        return new Promise((resolve, reject) => {
            api.post(`/kids/?${url}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    toastr.success('Report submitted successfully');
                    setIsLoading(false)
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                    resolve(response.data)
                })
                .catch(error => {
                    errorInterceptor(error)
                    setIsLoading(false)
                    reject(error)
                })
        })

    }

    const handlePlaceSelect = async (place) => {
        // if place is not null
        if (place) {
            // get latitude and longitude
            const results = await geocodeByAddress(place.label);
            const latLng = await getLatLng(results[0]);
            // set latitude and longitude
            setLatitude(latLng.lat)
            setLongitude(latLng.lng)
        }
        setPlace(place);
    }


    useEffect(() => {
        if (place && place !== '') {
            setValue("location_last_seen", place.label)
            setLocationError(null)
        }
    }, [place, setValue])

    useEffect(() => {
        if (selectedGender && selectedGender !== '') {
            setValue("gender", selectedGender)
        }
    }, [selectedGender, setValue])

    useEffect(() => {
        if (selectedStation && selectedStation !== '') {
            setValue("station_id", parseInt(selectedStation))
        }
    }, [selectedStation, setValue])

    const serializeUrl = (obj) => {
        var str = "";
        for (var key in obj) {
            if (key !== 'file') {
                if (str !== "") {
                    str += "&";
                }
            }
            str += key + "=" + encodeURIComponent(obj[key]);
        }

        return str;
    }

    const onImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            setImage(URL.createObjectURL(img))
        }
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        const croppedImage = getCroppedImg(image, croppedAreaPixels);
        setCroppedImage(croppedImage);
    }

    return (
        <>
            <Header link={props.link} />
            <main>
                <SubHeader title={'Submit a post'} description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'} />
                <Container style={{ marginBottom: 100 }}>
                    <Row style={{ marginTop: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Details</h1>
                        <p className='description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </Row>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row style={{ marginTop: 50 }}>
                            <Col md={6} lg={6}>
                                <Label forInput="first_name" value="First Name" />
                                <CustomInput
                                    control={control}
                                    name="first_name"
                                    placeholder={'First Name'}
                                    rules={{ required: "First name is required" }}
                                />
                                {errors.first_name?.type === 'required' && <p role="alert" className='error-text'>First name is required</p>}
                            </Col>
                            <Col md={6} lg={6}>
                                <Label forInput="last_name" value="Last Name" />
                                <CustomInput
                                    control={control}
                                    name="last_name"
                                    placeholder={'Last Name'}
                                    rules={{ required: "Last name is required" }} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col md={6} lg={6}>
                                <Label forInput="date_of_birth" value="Date of Birth" />
                                <CustomInput
                                    control={control}
                                    name="date_of_birth"
                                    placeholder={'Date of Birth'}
                                    type="date"
                                    rules={{ required: "Date of birth is required" }} />
                            </Col>
                            <Col md={6} lg={6}>
                                <Label forInput="gender" value="Gender" />
                                <CustomSelect
                                    placeholder={'Gender'}
                                    selectedOption={selectedGender}
                                    options={genders}
                                    handleChange={handleSelectChange}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col md={6} lg={6}>
                                <Label forInput="date_last_seen" value="Date Last Seen" />
                                <CustomInput
                                    control={control}
                                    name="date_last_seen"
                                    type="date"
                                    placeholder={'Date Last Seen'}
                                    rules={{ required: "Date last seen is required" }} />
                            </Col>
                            <Col md={6} lg={6}>
                                <Label forInput="nearest_police_station" value="Nearest Police Station" />
                                <CustomSelect
                                    control={control}
                                    placeholder={'Nearest Police Station'}
                                    name="station_id"
                                    selectedOption={selectedStation}
                                    options={policeStations}
                                    handleChange={handleStationChange}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col>
                                <Label forInput="location_last_seen" value="Location Last Seen" />
                                <GooglePlacesAutocomplete
                                    apiKey="AIzaSyAxsC3xRvMPYBYAL7B0LGZ3Pd54j0hpVxI"
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: ['zw'],
                                        },
                                    }}
                                    selectProps={{
                                        onChange: handlePlaceSelect,
                                        value: place,
                                        placeholder: 'Location Last Seen',
                                        isClearable: true,
                                    }}
                                />
                                {locationError && <p role="alert" className='error-text'>{locationError}</p>}

                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col>
                                <Label forInput="circumstances" value="Circumstances" />
                                <CustomInput control={control} textArea={true} name="circumstances" rows="5" placeholder="Circumstances" autoComplete="circumstances" />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col md={6} lg={6}>
                                <Label forInput="contact_phone_number" value="Contact Phone Number" />
                                <PhoneInput
                                    className="input-control"
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={setPhone}
                                    country="ZW"
                                    defaultCountry='ZW'
                                />
                                {phoneError && <p role="alert" className='error-text'>{phoneError}</p>}
                            </Col>
                            <Col md={6} lg={6}>
                                <Label forInput="contact_email" value="Contact Email" />
                                <CustomInput
                                    control={control}
                                    name="contact_email"
                                    placeholder={'Email'} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col md={6} lg={6}>
                                <Label forInput="picture" value="Picture" />
                                <CustomFileInput placeholder={'Image Upload'} type="file" onImageChange={onImageChange} />
                                {imageError && <p role="alert" className='error-text'>{imageError}</p>}
                            </Col>
                            <Col md={6} lg={6}>
                                <Label forInput="relationship" value="Relationship (e.g. Mother)" />
                                <CustomInput
                                    control={control}
                                    name="relationship"
                                    placeholder={'Relationship'} />
                            </Col>
                        </Row>

                        {image && <Row style={{ marginTop: 50 }}>
                            <Col md={6} lg={6}>
                                <div className="cropper">
                                    <Cropper
                                        image={image}
                                        aspect={CROP_AREA_ASPECT}
                                        crop={crop}
                                        zoom={zoom}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                        onCropAreaChange={(croppedArea) => {
                                            setCroppedArea(croppedArea);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col md={6} lg={6}>
                                <div className="viewer">
                                    <div>{croppedArea && <Output croppedArea={croppedArea} image={image} />}</div>
                                </div>
                            </Col>
                        </Row>}

                        <div style={{ marginTop: 20 }} className='text-left text-white py-3'>
                            <div className='mb-4'>
                                {!isLoading && <CustomButton className='primary-button py-3' fullWidth={true} href='#!' role='button'>
                                    Submit Report
                                </CustomButton>}
                                {isLoading &&
                                    <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                                        <ColorRing
                                            visible={true}
                                            height="50"
                                            width="50"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                        />
                                    </CustomButton>}
                            </div>
                            <div>
                                <CustomButton className='primary-button-outline' fullWidth={true} href='#!' role='button'>
                                    Cancel
                                </CustomButton>
                            </div>
                        </div>
                    </form>
                </Container>
            </main>

            <Footer />
        </>
    )
}

export default NewReport