import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { Link } from 'react-router-dom'

export default function About(props) {
    return (
        <>
            <Header link={props.link} />
            <main>
                <SubHeader title={'About Us'} description={'Kids Alert is led by a dedicated team of individuals who are passionate about ensuring the safety and well-being of missing and vulnerable children. Our team consists of experienced professionals with a range of backgrounds in law enforcement, social work, advocacy, and technology.'} />
                <Container>
                    <Row style={{ marginTop: 50 }}>
                        <p className='description'>
                            Kids Alert App is here for our community to add eyes in preventing vulnerable children from child trafficking and losing their lives. We provide relevant in all seasons, before and after a crisis to provide necessary information that will equip communities. The special aspect brought in by this App is prayer. Social injustice to children is caused by demonic influences which cause men to be evil and lack sympathy and empathy. With KIDS ALERT we are here to make a difference and contribute positively to the community and society by being an awareness post in reduction of missing and lost children in southern Africa mainly in Zimbabwe, Zambia, Botswana and the Republic of South Africa. This package has been tapped by the surge of documented and undocumented cases of young victims who go missing voluntarily or abducted.
                        </p>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Meet the people who lead and support Kids Alert</h1>
                        <p className='description'>
                            Together, we work tirelessly to raise awareness about the issue of missing children, support families in crisis, and advocate for policies that protect children from harm. Get to know the faces behind Kids Alert and learn more about their backgrounds, expertise, and commitment to our mission.
                        </p>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Our Trustees</h1>
                            <p className='description'>
                                Our Trustees trustees constitue a Board which is elected every 2 years to ensure competence.
                            </p>
                        </Col>
                        <Col>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Our directors and spokespeople</h1>
                            <p className='description'>
                                <b>Prudence Shoko: </b>Co-Founder, <b>Gerald Mangena: </b>Financial Director, <b>Arnold Doma: </b>Response & Awareness Manager
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Our Founders</h1>
                            <p className='description'>
                                Pastor Gift Mthandazo Shoko. Read more on <a href='https://www.amenkids.org' target='_blank' rel="noreferrer">www.amenkids.org</a>
                            </p>
                        </Col>
                        <Col>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Our Funders</h1>
                            <p className='description'>Amen Kids Trust; Community Volunteers; Social Organizations; Churches</p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Community ambassadors</h1>
                            <p className='description'>Amen Kids Trust</p>
                        </Col>
                        <Col>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Our Patrons</h1>
                            <p className='description'>Amen Kids Trust; Community Volunteers; Social Organizations; Churches</p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Terms and Conditions</h1>
                            <p className='description'>
                                To view our Terms and Conditions, please click on the link below.
                                <br />
                                <Link to={'/terms-and-conditions'} className="link text-main">Terms and Conditions</Link>
                            </p>
                        </Col>
                        <Col>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Privacy Policy</h1>
                            <p className='description'>
                                To view our Privacy Policy, please click on the link below.
                                <br />
                                <Link to={'/privacy-policy'} className="link text-main">Privacy Policy</Link>
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>How we work</h1>
                            <p className='description'>
                                Our policy is to work closely with the Police, Victim Friendly Unit and Accredited Children Organizations Kids Alert App operates under the Child Care legislation of the international laws. Our practice is to make sure that parents and victims get adequate mental help from regulated agencies also to safeguard the information voluntarily provided and stored.
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 30, marginBottom: 100 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Ways to support us</h1>
                        <Col>
                            <h1 className='sub-title-small' style={{ marginBottom: 10 }}>Give time</h1>
                            <p className='description mb-4'>
                                Find sometime to share our website, social media pages, and missing child posters with your friends, family, and community. The more people know about our cause, the more likely we are to find missing children.
                            </p>
                        </Col>
                        <Col>
                            <h1 className='sub-title-small' style={{ marginBottom: 10 }}>Give Socially</h1>
                            <p className='description'>We are always in need of volunteers to help with our search efforts, fundraising events, and administrative tasks. Contact us to find out how you can get involved.</p>
                        </Col>
                        <Col>
                            <h1 className='sub-title-small' style={{ marginBottom: 10 }}>Give Anything</h1>
                            <p className='description mb-4'>Your financial support can make a big difference in our search for missing children. You can donate online through our website or by getting in touch with.</p>
                        </Col>
                    </Row>
                </Container>

            </main >
            <Footer />
        </>
    )
}
