import React from 'react';

export default function CustomButton({ type = 'submit', className = '', fullWidth = false, processing, children, onClick, disabled }) {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`${className}`}
            disabled={disabled}
            style={{
                width: fullWidth ? '100%' : 'auto',
                opacity: disabled ? 0.6 : 1,
            }}
        >
            {children}
        </button>
    );
}
