import axios from 'axios';
import React, { useEffect } from 'react'
import Header from '../components/Header';
import { Container, Row } from 'react-bootstrap';
import OTPInput from 'react-otp-input';
import CustomButton from '../components/CustomButton';
import { ColorRing } from 'react-loader-spinner';
import CountDownTimer from '../components/CountDownTimer';
import { useLocation } from 'react-router-dom';
import toastr from '../core/toast';
import { AuthContext, useAuth } from '../context/AuthContext';
import { useForm } from 'react-hook-form';

function ConfirmAccount(props) {

  const location = useLocation();
  const auth = useAuth();
  const { refreshUserInfo } = React.useContext(AuthContext)
  const [isLoading, setIsLoading] = React.useState(false);
  const [isResending, setIsResending] = React.useState(false);
  const [otp, setOtp] = React.useState('')
  const [isResendEnable, setIsResendEnable] = React.useState(true)
  const { email, phone_number } = location.state || {};

  const { setValue, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true)
    axios.post(`/users/verify/otp`, data).then(async (response) => {
      let userInfo = response.data
      refreshUserInfo(userInfo)
    }).catch((error) => {
      console.log(error)
      setIsLoading(false);
    });
  }

  const onResendPressed = () => {
    setIsResending(true)
    axios.post(`/users/resend/otp`, {}).then(async (response) => {
      setIsResending(false);
      toastr.success('OTP Resent successfully')
      setIsResendEnable(true)
    }).catch((error) => {
      console.log(error)
      setIsResending(false);
    });
  }

  function obscureEmail(email) {
    const [name, domain] = email.split('@');
    return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
  };

  function obscurePhone(phone) {
    return `${phone.substring(0, 6)}${new Array(phone.length - 7).join('*')}${phone.slice(-2)}`;
  };

  useEffect(() => {
    otp.length === 4 && setValue('otp', parseInt(otp))
    // eslint-disable-next-line
  }, [otp]);

  const deactivateResetIsEnabled = () => {
    setIsResendEnable(false)
  }

  return (
    <>
      <Header link={props.link} />
      <main>
        <Container style={{
          marginBottom: 100,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 600,
        }}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Row style={{ marginTop: 50 }} className='text-center'>
              <h1 className='sub-title' style={{ marginBottom: 10 }}>Confirm Your Account</h1>
              <p className='description'>
                Please enter the OTP sent to your email {obscureEmail(email)} and {obscurePhone(phone_number)}. <br />
              </p>
            </Row>
            <Row style={{ marginTop: 20 }} className='justify-content-center align-items-center'>
              <div style={{ marginBottom: 10 }}>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  inputStyle={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '4px',
                    border: '1px solid #e5e5e5',
                    fontSize: '18px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    marginRight: '10px',
                    marginLeft: '10px',
                    color: '#000',
                    textAlign: 'center'
                  }}
                />
              </div>
            </Row>
            <div style={{ marginTop: 10 }} className='text-center text-white py-3'>
              <div>
                {!isLoading &&
                  <CustomButton fullWidth={true} className='primary-button' href='#!' role='button' disabled={otp.length !== 6}>
                    Confirm
                  </CustomButton>}
                {isLoading &&
                  <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                    <ColorRing
                      visible={true}
                      height="50"
                      width="50"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    />
                  </CustomButton>
                }
                {!isResending &&
                  <CustomButton className='link-button' onClick={onResendPressed} disabled={isResendEnable}>
                    {isResendEnable ? <CountDownTimer isResendEnable={isResendEnable} setResendEnabled={deactivateResetIsEnabled} /> : "Resend Code"}
                  </CustomButton>
                }
                {isResending && <CustomButton disabled={true} onClick={onResendPressed} className='primary-button'>
                  <ColorRing
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                  />
                </CustomButton>}
              </div>
              <Row style={{ marginTop: 20 }} className='text-center'>
                <font className='link-text'><CustomButton onClick={auth.logout} className='link-button text-danger'>Back to Login</CustomButton></font>
              </Row>
            </div>
          </form>
        </Container>
      </main>
    </>
  )

}

export default ConfirmAccount