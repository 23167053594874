import React, { useEffect, useRef } from 'react';

export default function CustomFileInput({
    type = 'text',
    name,
    value,
    placeholder,
    className,
    autoComplete,
    required,
    isFocused,
    handleChange,
    id,
    disabled,
    min,
    max,
    maxLength,
    onImageChange,
}) {
    const input = useRef();

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, [isFocused]);

    return (
        <div className="">
            <input
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                className='file-input-control'
                ref={input}
                autoComplete={autoComplete}
                required={required}
                onChange={onImageChange}
                id={id}
                disabled={disabled}
                min={min}
                max={max}
                maxLength={maxLength}
                accept='image/*'
            />
        </div>
    );
}
