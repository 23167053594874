import React from 'react'
import Header from '../components/Header'
import { Container, Row } from 'react-bootstrap'
import CustomInput from '../components/CustomInput'
import { useForm } from 'react-hook-form'
import Label from '../components/Label'
import CustomButton from '../components/CustomButton'
import { ColorRing } from 'react-loader-spinner'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import toastr from '../core/toast'
import { errorInterceptor } from '../core/errorInterceptor'
import api from '../core/api'

function ResetPassword(props) {

    const [isLoading, setIsLoading] = React.useState(false);
    const location = useLocation()
    const navigate = useNavigate()
    const { control, handleSubmit, formState: { errors }, watch } = useForm();
    const { email, otp } = location.state || {};

    const pwd = watch('password')

    // handle form submit
    const onSubmit = (data) => {
        setIsLoading(true)

        let dataToSend = {
            reset_password_token: otp,
            email: email,
            new_password: data.password,
            confirm_password: data.confirm_password
        }

        return new Promise((resolve, reject) => {
            api.post('/auth/change-password', dataToSend)
                .then(response => {
                    resolve(response)
                    toastr.success("Password has been reset successfully");
                    setTimeout(() => {
                        setIsLoading(false);
                        // go to login screen
                        navigate('/sign-in', {state: { email: email}})
                    }, 2000)
                })
                .catch(error => {
                    reject(error)
                    errorInterceptor(error);
                    setIsLoading(false);

                });
        });
    };

    return (

        <>
            <Header link={props.link} />
            <main>
                <Container style={{
                    marginBottom: 100,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 600,
                }}>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row style={{ marginTop: 50 }}>
                            <h1 className='sub-title' style={{ marginBottom: 10 }}>Reset Password</h1>
                            <p className='description'>Enter your new password below.
                                Your password must be at least 8 characters long and please
                                make sure to use a strong password.</p>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <div style={{ marginBottom: 10 }}>
                                <Label forInput="password" value="Password" />
                                <CustomInput
                                    control={control}
                                    name="password"
                                    placeholder={'Password'}
                                    type='password'
                                    rules={{
                                        required: 'Password is required',
                                        minLength: {
                                            value: 6,
                                            message: 'Password should have a minimum of 3 characters'
                                        }
                                    }}
                                    errors={errors.password}
                                />
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                <Label forInput="confirm_password" value="Password" />
                                <CustomInput
                                    control={control}
                                    name="confirm_password"
                                    placeholder={'Confirm Password'}
                                    type='password'
                                    rules={{
                                        required: 'Password Confirmation is required',
                                        validate: value => value === pwd || 'Passwords do not match'
                                    }}
                                    errors={errors.password}
                                />
                            </div>
                        </Row>
                        <div style={{ marginTop: 10 }} className='text-left text-white py-3'>
                            <div>
                                {!isLoading && <CustomButton fullWidth={true} className='primary-button' href='#!' role='button'>
                                    Reset Password
                                </CustomButton>}
                                {isLoading &&
                                    <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                                        <ColorRing
                                            visible={true}
                                            height="50"
                                            width="50"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                        />
                                    </CustomButton>
                                }
                            </div>
                            <Row style={{ marginTop: 20 }} className='text-center'>
                                <font className='link-text'>Remembered Password? <Link to={'/sign-in'} className='link text-danger'>Login</Link></font>
                            </Row>
                        </div>
                    </form>
                </Container>
            </main>
        </>

    )
}

export default ResetPassword