import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import CustomLink from './CustomLink';
import logo from '../assets/kidsalert.png'
import { useAuth } from '../context/AuthContext';
import { NavDropdown } from 'react-bootstrap';


function Header(props) {

    const auth = useAuth();
    const userToken = auth?.user?.access_token;

    const headerStyles = {
        notActive: {
            fontWeight: '600',
            fontSize: 18,
            color: '#28367C',
        },
        active: {
            fontWeight: '600',
            fontSize: 18,
            color: '#E92C24',
        }
    }
    const location = props.link

    const [showDropdown, setShowDropdown] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('@userToken')
        localStorage.removeItem('@userInfo')
        window.location.reload()
    }

    const handleDropdownShow = () => {
        setShowDropdown(!showDropdown);
    }

    return (
        <header>
            <Navbar sticky="top" expand="lg" collapseOnSelect style={{ height: 80, backgroundColor: 'white', borderBottom: '1px solid lightGrey' }}>
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            height="55"
                            className="d-inline-block align-top"
                            alt="Kids Alert"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="ms-auto my-4 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                            activeKey={location}
                        >
                            <Nav.Link href="/" className='me-4 link' style={location === '/' ? headerStyles.active : headerStyles.notActive}>Home</Nav.Link>
                            <Nav.Link href="/help" className='me-4' style={location === '/help' ? headerStyles.active : headerStyles.notActive}>Help</Nav.Link>
                            <Nav.Link href="/news" className='me-4' style={location === '/news' ? headerStyles.active : headerStyles.notActive}>News</Nav.Link>
                            <Nav.Link href="/support" className='me-4' style={location === '/support' ? headerStyles.active : headerStyles.notActive}>Support</Nav.Link>
                            <Nav.Link href="/about-us" className='me-4' style={location === '/about-us' ? headerStyles.active : headerStyles.notActive}>About Us</Nav.Link>
                            {(userToken && userToken !== null) ? null : <Nav.Link href="/sign-in" className='me-4' style={location === '/sign-in' ? headerStyles.active : headerStyles.notActive}>Login</Nav.Link>}
                            {(userToken && userToken !== null) ? null : <Nav.Link href="/sign-up" className='me-4' style={location === '/sign-up' ? headerStyles.active : headerStyles.notActive}>Register</Nav.Link>}
                            {(userToken && userToken !== null) ? (
                                <NavDropdown style={headerStyles.notActive} title="Account" className='me-4 dropdown' show={showDropdown} onClick={handleDropdownShow}>
                                    <NavDropdown.Item href="/my-reports">My Reports</NavDropdown.Item>
                                    <NavDropdown.Item href="/update-profile">Profile Update</NavDropdown.Item>
                                    <NavDropdown.Item href="/change-password">Change Password</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            ) : null}
                        </Nav>
                        <CustomLink url="/contact-us" className='page-link-outline'>Contact Us</CustomLink>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </header>
    )
}

export default Header