import React, { useContext, useState } from 'react'
import Header from '../components/Header'
import { Container, Row } from 'react-bootstrap'
import CustomInput from '../components/CustomInput'
import { useForm } from 'react-hook-form'
import Label from '../components/Label'
import CustomButton from '../components/CustomButton'
import { AuthContext, useAuth } from '../context/AuthContext'
import { ColorRing } from 'react-loader-spinner'
import PhoneInput from 'react-phone-number-input'
import toastr from '../core/toast'
import api from '../core/api'
import { errorInterceptor } from '../core/errorInterceptor'

function UpdateProfile(props) {

  const { logout, refreshUserInfo } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const userJson = localStorage.getItem('@userInfo');
  let user;
  if (userJson) {
    try {
      user = JSON.parse(userJson);
    } catch (e) {
      console.error('Error parsing user from localStorage:', e);
      // Handle the error appropriately, e.g. by setting user to null or an empty object
      user = null;
    }
  } else {
    user = null;
  }

  // remove all + signs from phone number and add 1 to the beginning of the number
  const defaultPhoneNumber = "+" + user?.phone_number?.replace(/\+/g, '')

  const [phoneError, setPhoneError] = useState(null);
  const [phone, setPhone] = useState(defaultPhoneNumber);

  // react hook form for form validation
  const { control, handleSubmit, formState: { errors } } = useForm(
    {
      defaultValues: {
        first_name: user?.first_name,
        last_name: user?.last_name,
        phone_number: user?.phone_number,
        email: user?.email,
      }
    }
  );

  // update user info
  const updateUserInfo = async () => {
    setIsLoading(true);

    // axios get request to get the user info
    auth.getRequest('/users/me/').then(response => {
      let userInfo = response
      refreshUserInfo(userInfo)
      setIsLoading(false);
    })
      .catch((error) => {
        if (error.response.status === 401) {
          logout()
        }
        setIsLoading(false);
      });
  }

  // handle form submit
  const onSubmit = (data) => {
    setIsLoading(true);

    // check if phone number is valid
    if (phone === null) {
      setPhoneError('Phone number is required')
      setIsLoading(false);
      return;
    }

    data.phone_number = phone

    return new Promise(async (resolve, reject) => {
      api.post('/users/profile/update', data)
        .then(response => {
          updateUserInfo();
          toastr.success('Profile updated successfully');
          setIsLoading(false);
          resolve(response);

        })
        .catch(error => {
          reject(error);
          if (error.response.status !== 401) {
            errorInterceptor(error);
          }
          if (error.response.status === 401) {
            logout()
          }
          setIsLoading(false);
        });
    })
  }

  return (
    <>
      <Header link={props.link} />
      <main>
        <Container style={{
          marginBottom: 100,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 600,
        }}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Row style={{ marginTop: 50 }}>
              <h1 className='sub-title' style={{ marginBottom: 10 }}>Profile</h1>
              <p className='description'>To update your account, please enter your new details below</p>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <div style={{ marginBottom: 10 }}>
                <Label forInput="first_name" value="First Name" />
                <CustomInput
                  control={control}
                  name="first_name"
                  placeholder={'First Name'}
                  rules={{ required: "First name is required" }}
                  errors={errors.username}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <Label forInput="last_name" value="Last Name" />
                <CustomInput
                  control={control}
                  name="last_name"
                  placeholder={'Last Name'}
                  rules={{ required: "Last name is required" }}
                  errors={errors.username}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <Label forInput="email" value="Email Address" />
                <CustomInput
                  control={control}
                  name="email"
                  placeholder={'Email Address'}
                  rules={{ required: "Email Address is required" }}
                  errors={errors.username}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <Label forInput="phone_number" value="Phone Number" />
                <PhoneInput
                  className="input-control"
                  placeholder="Enter Phone Number"
                  value={phone}
                  onChange={setPhone}
                  country="ZW"
                  defaultCountry='ZW'
                />
                {phoneError && <p role="alert" className='error-text'>{phoneError}</p>}
              </div>
            </Row>
            <div style={{ marginTop: 10 }} className='text-left text-white py-3'>
              <div className='mb-4'>
                {!isLoading && <CustomButton fullWidth={true} className='primary-button' href='#!' role='button'>
                  Update Profile
                </CustomButton>}
                {isLoading &&
                  <CustomButton fullWidth={true} disabled={true} className='primary-button' href='#!' role='button'>
                    <ColorRing
                      visible={true}
                      height="50"
                      width="50"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    />
                  </CustomButton>
                }
              </div>
              <div>
                <CustomButton className='primary-button-outline' fullWidth={true} href='#!' role='button'>
                  Cancel
                </CustomButton>
              </div>
            </div>
          </form>
        </Container>
      </main>
    </>

  )
}

export default UpdateProfile