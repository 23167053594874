// import toast from "./toast";

import toastr from "./toast";

export const errorInterceptor = (error) => {

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        if (error.response.status === 401) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 400) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 404) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 500) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 503) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 504) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 502) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 403) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 422) {
            let err = error.response.data.detail;
            console.log(error.response.data);
            return toastr.error(err.message);
        } else if (error.response.status === 409) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 405) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 406) {
            let err = error.response.data.detail;
            return toastr.error(err.message);
        } else if (error.response.status === 422) {
            return toastr.error({ message: "Unprocessed Input" });
        } else {
            // throw error;
            return toastr.error({ message: "Something went wrong" });
        }
    }
}